@keyframes rotate {
    100% {transform: rotate(360deg)}
}
@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
@keyframes underline-animated {
    0% {
      right: 100%;
    }
    50% {
      right: 0;
      left: 0;
    }
    100% {
      right: 100%;
      left: 0;
    }
}

//CLASSES
.pointer-events-none {
    pointer-events: none;
}
.labels {
    font-family: $family-name-quick-bold;
    color: $darkgrey;
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: none;
}
.links {
    max-width: max-content;
    width: 100%;
    height: fit-content;
    min-width: min-content;
    position: relative; 
    color: black;
    font-weight: bold;
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: inherit;
        height: 1px;
        border: 1px solid $sky;
        border-radius: 10%;
        opacity: 0;
        -webkit-transform: translateY(0.5rem);
        -ms-transform: translateY(0.5rem);
        transform: translateY(0.5rem);
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
    }
    &:hover {
        color: $sky;
        &:after {
        opacity: 1;
        transform: translateY(0rem);           
        }
    }
    &.active {
        color: $sky;
        &:after {
            opacity: 1;
        }
    }
}
.drop-button {
    font: normal normal 600 2.25rem/3rem Red Hat Display;
    text-align: left;
    size: 2.25rem;
    background: transparent 0% 0% no-repeat padding-box;
    width: 50%;
    height: 4.625rem;
    box-shadow: 20px 20px 60px #d9d2d5;
    border: none;
    cursor: pointer;
}
.underline-effect {
    position: relative;
    &::after {
        content: "";  
        position: absolute;
        bottom: -0.125rem;
        left: 0rem;
        right: 0rem;
        background: white;
        border-radius: 2rem;     
        opacity: 0;
        height: 3px;
        width: inherit; 
        transform: translateY(0rem);
        opacity: 0;
    }
    &:hover::after {
        opacity: 1;
        transition: all 0.5s;
        transform: translateY(0.2rem);
    }
}
.left-button {
    border-radius: 1rem 0 0 1rem;
}
.right-button {
    border-radius: 0 1rem 1rem 0;  
}
.indicator-short {
    cursor: pointer;
    position: relative;
    width: 0.5rem;
    height: 0.5rem;
    background-color: $marine;
    border-radius: 1rem; 
    transition: all 300ms ease;
}
.indicator-long {
    cursor: pointer;
    position: relative;
    width: 2rem;
    height: 0.5rem;
    background-color: $grape;
    border-radius: 1rem; 
    transition: all 300ms ease;
}
.rectangle {
    color: $darkgrey;
    border-radius: 30px;
    background: $pale-blue;
    background: #F4F7FA;
    width: 10rem;
    height: 10rem;
    display: flex;
    flex-direction: column; 
    align-content: center;
    justify-content: center;
    align-items: center;
    box-shadow: 20px 20px 35px #47a8bd33,
                -20px -20px 35px #ffffff,
                inset 0px 0px 0px #47a8bd33,
                inset 0px 0px 0px #ffffff;
    transition: all 100ms ease;
    &:hover {
        box-shadow: 0px 0px 0px #47a8bd33,
                    20px 20px 35px #ffffff,
                    inset 10px 10px 35px #47a8bd33,
                    inset -10px -10px 35px #ffffff;
    }
    .text {
        margin-top: 0.5rem;
    }
    &.is-paleblue-shadow {
        box-shadow: 20px 20px 35px #0000001A,
                    20px 20px 0px $pale-blue,
                    inset 0px 0px 0px #0000001A,
                    inset 0px 0px 0px $pale-blue;
        transition: all 100ms ease;
        &:hover {
            box-shadow: 0px 0px 0px #0000001A,
                        20px 20px 35px $pale-blue,
                        inset 10px 10px 35px #0000001A,
                        inset -10px -10px 35px $pale-blue;
        }   
        &.is-not-clickable {
            &:hover {
                box-shadow: 20px 20px 35px #0000001A,
                            20px 20px 0px $pale-blue,
                            inset 0px 0px 0px #0000001A,
                            inset 0px 0px 0px $pale-blue;
            }
        }
    }
    &.is-white-shadow {
        box-shadow: 20px 20px 35px #0000001A,
                    20px 20px 0px #ffffff,
                    inset 0px 0px 0px #0000001A,
                    inset 0px 0px 0px #ffffff;
                    transition: all 100ms ease;
        &:hover {
        box-shadow: 0px 0px 0px #0000001A,
                    20px 20px 35px #ffffff,
                    inset 10px 10px 35px #0000001A,
                    inset -10px -10px 35px #ffffff;
        }
        &.is-not-clickable {
            &:hover {
                box-shadow: 20px 20px 35px #0000001A,
                            20px 20px 0px #ffffff,
                            inset 0px 0px 0px #0000001A,
                            inset 0px 0px 0px #ffffff;
                            transition: all 100ms ease;
            }
        }
    }
    &.is-smaller {
        background-color: white;
        color: $marine;
        width: 8rem;
        height: 8rem;
    }
}   
.thumb-wrapper {
    position: relative;
    padding-top: $padding16x9;
    overflow: hidden;
    display: block;
    &.is-3x4 {
        padding-top: $padding3x4;
    }
    .image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 0;
    }
}
.icon-close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: block;
    width: 40px;
    height: 40px;
    font-size: 0;
    transition: all 150ms ease; 
    &:hover {
        padding: 0.0625rem;
        border-radius: 1rem;
        background-color: $pale-blue;
    }
    &:before, &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 20px;
        background-color: #000;
        transform: rotate(45deg) translate(-50%, -50%);
        transform-origin: top left;
        content: '';
    }
    &:after {
        transform: rotate(-45deg) translate(-50%, -50%);
    }
}
.counter-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: $radius-small;
    width: fit-content;
    height: 100%;
    position: sticky; 
    .counter {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.375rem 0;
        width: 2rem;
        height: 100%;
        font-size: 1.25rem;
        border: 1px solid #EAEEF2;
        border-top-left-radius: $radius-small;
        border-bottom-left-radius: $radius-small;
        box-shadow: inset 10px 10px 6px -7px #00000d10;
    }
    .buttons-wrapper {
        min-height: 100%;
        max-height: fit-content;
        width: 2rem;
        background: #EAEEF2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        box-shadow: inset 7px 8px 30px -6px #00000d10;
        .plus-button {
            background-color: #EAEEF2;
            border: #EAEEF2;
            border-top-right-radius: $radius-small;
            width: 100%;
            height: 50%;
            box-shadow: inset 2px 2px 6px #00000d15;
            &:hover {
                box-shadow: inset 2px 2px 6px #00000d45;
                cursor: pointer; 
            }
        }
        .minus-button {
            background-color: #EAEEF2;
            border: none;
            border-bottom-right-radius: $radius-small;
            width: 100%;
            height: 50%;
            box-shadow: inset 2px 2px 6px #00000d15;
            &:hover {
                box-shadow: inset 2px 2px 6px #00000d45;
                cursor: pointer;
            }
        }
    }
}
.dots {
    position: relative;
    cursor: pointer;
    border-radius: $radius;
    padding: 1rem;
    background-color: transparent;
    transition: background-color 250ms ease;
    &:hover {
        background-color: white;
    }
    .dots-dropdown {
        position: absolute;
        top: 50%;
        right: 1rem;
        background: white;
        padding: 1.25rem 2.125rem;
        border-radius: $radius;
        box-shadow: 0px 0px 10px #0000001A;
        li {
            font-weight: bold;
            margin-bottom: 0.5rem;
            color: $black60;
            transition: all 250ms ease; 
            &.is-disabled {
                color: $black20;
                &:hover {
                    color: $black20;
                }
            }
            &:hover {
              color: $sky;
            }
        }
        &.is-open {
            display: none;
        }
    }
    @media all and (max-width: $mobileMax) {
        padding: 0.5rem;
        &:hover {
            background-color: transparent;
        }
    }
}
.dots-dropdown {                    //myServers-Mobile
    position: absolute;
    top: 4rem;
    right: 1rem;
    background: white;
    padding: 1.25rem 2.125rem;
    border-radius: $radius;
    box-shadow: 0px 0px 10px #0000001A;
    z-index: 10;
    li {
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: $black60;
        transition: all 250ms ease; 
        &.is-disabled {
            color: $black20;
            &:hover {
                color: $black20;
            }
        }
        &:hover {
          color: $sky;
        }
    }
    &.is-open {
        display: none;
    }
}
.delete-icon {
    background-color: transparent;
    transition: all 300ms ease;
    &:hover {
        padding: -1rem;
        background-color: $pale-blue; 
        border-radius: 0.25rem;
    }
}
.red-dot {
    color: white;
    position: absolute;
    top: 0;
    right: 0.125rem;
    background-color: #FF6B95;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.current-status {
    &.is-green {
        display: flex; 
        align-items: center;
        &:before {
            content: "";
            display: block;
            width: 1rem;
            height: 1rem;
            border-radius: 1rem;
            background: #76D3AB;
            margin-right: 0.5rem;
        }
    }
    &.is-red {
        display: flex; 
        align-items: center;
        &:before {
            content: "";
            display: block;
            width: 1rem;
            height: 1rem;
            border-radius: 1rem;
            background: #FD6B95;
            margin-right: 0.5rem;
        } 
    }
    &.is-loading {
        display: flex; 
        align-items: center;
        border-radius: 50%;
        position: relative;
        &.is-bigger {
            &:before {
                width: 5rem !important; 
                height: 5rem;
                border: 6px solid #47A8BD;
            }
        }
    }
    &.is-loading::before {
        content: "";
        width: 1rem !important; 
        height: 1rem;
        inset: 0;
        border-radius: 50%;
        border: 2px solid #47A8BD;
        padding: 0.25rem;
        margin-right: 0.5rem;
        animation: prixClipFix 2s linear infinite;
    }  
    &.is-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) ;
    }
}
.effect:hover {
    color: $marine;
    position: relative;
    &:after {
        content: "";
        height: 2px;
        position: absolute;
        top: 1.5rem;
        left: 0;
        background: $marine;
        transition: all;
    }
    &.underlined-animated::after {
        animation: underline-animated 2s forwards;
    }
}
.get-btn {
    color: #fff;
    display: block;
    padding: 0.625rem 0.875rem 0.625rem 1.25rem;
    width: 4.5rem;
    height: 2.75rem;
    transition: all 0.2s ease;
    text-align: left;
    cursor: pointer;
    border-radius: 1rem;
    border: 0;
    font-weight: bold;
    font-size: 1rem;
    white-space: nowrap;
    span {
        padding-right: 0.375rem;
        width: 2rem;
    }
    &:after {
        content: "→";
        opacity: 0;
        transition: all 0.2s ease;
    }
    &:hover {
        width: 5.5rem;
        &:after {
            opacity: 1;
        }
        &.has-background-marine {
            background-color: $blue;
        }
        &.has-background-grape {
            background-color: $grape-lighter;
        }
        &.has-background-pink {
            background-color: $pink-light;
        }
        &.has-background-sky {
            background-color: $sky;
        }
    } 
}
.dashed {
    border: 1px dashed #A3A3A3;
}
.tick {
    background-color: white;
    border-radius: 50%;
    border: 2px #A8A8A8 solid; 
    width: 1.5rem;
    height: 1.5rem;
    &.active {
        border: unset; 
        background-image: url("../../../public/images/checkmark.svg");
        background-size: contain;
    }
}
.password-requirements {
    margin-right: 0.5rem;
    font-size: 1rem;
    &.is-dot {
        &:after {
            content: "•";
        }
    }
    &.is-x {
        &:after {
            content: "✕";
            color: $pink;
        }
    }
    &.is-tick {
        &:after {
            content: "✔";
            color: green;
        }
    }
}
.errors {
    color: $pink;
}
.eye {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    transition: all 250ms ease;
    &:hover {
        filter: invert(100%) sepia(100%) saturate(50%) hue-rotate(251deg) brightness(0%) contrast(100%);
    }
}
.radio-panel {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: $white;
    border-radius: $radius;
    box-shadow: 0px 0px 20px #0000000D;
    padding: 1rem;
    cursor: pointer;
    height: 100%;
    transition: box-shadow 250ms ease;
    &:hover {
      box-shadow: 0px 0px 15px $black20;
    }
}
.mouse-wrapper {                     
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);    
    align-self: end;      
    padding: 1rem  1.5rem;  
    background: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;         
    &:before {
        content: "";
        width: 2rem;
        height: 2rem;
        position: absolute;
        bottom: -1rem;
        left: -1rem;
        background: transparent;
        border: 16px solid white;
        border-top: transparent;
        border-left: transparent;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 0px;
    }
    &:after {
        content: "";
        width: 2rem;
        height: 2rem;
        position: absolute;
        bottom: -1rem;
        right: -1rem;
        background: transparent;
        border: 16px solid white;
        border-top: transparent;
        border-right: transparent;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
    .mouse-text {
        white-space: nowrap;
        position: absolute;
        bottom: 5rem;
        left: -50%;
    }
}
.arrow-down {
    width: 2rem;
    height: 2rem;
    transform: rotate(-90deg);
    background-color: $marine;
    background-image: url(../../../public/images/arrow-down-white.svg);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    z-index: 1;
    transition: all 250ms ease;
    &.is-black {
        background-color: $pale-blue;
        background-image: url(../../../public/images/arrow-down-black.svg);
    }
    &.is-reverted {
        transform: rotate(90deg);
    }
}
.loader-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}
////////////////////////


// FONT
.is-regular {
    font-weight: normal;
}
.quick-font {
    font-family: $family-name-quick-bold; 
}
.luck {
    font-family: $family-name-luck;
}
.description-l {
    font-family: $family-name-quick-500;
    font-size: 1.125rem;
    line-height: 2rem;
}
.description-s {
    font-family: $family-name-quick-500;
    font-size: 1rem;
    line-height: 1.5rem;
}


.is-half {
    width: 50%;
}

// SIZE
.is-size-2-half {
    font-size: 2.25rem; //36 px
}
.is-size-3-half {
    font-size: 1.75rem; //28 px
}
.is-size-6-half {
    font-size: 0.875rem; //14 px
}
.is-size-5-half {
    font-size: 1.125rem; //18 px
}

// COLOR
.has-text-burgundy {
    color: $burgundy;
}
.has-text-sky {
    color: $sky;
}
.has-text-darkgrey {
    color: $darkgrey;
}
.has-text-marine {
    color: $marine;
}
.has-text-marine-light {
    color: $marine-light;
}
.has-text-marine-lighter {
    color: $marine-lighter;
}
.has-text-grape {
    color: $grape;
}
.has-text-grape-light {
    color: $grape-light;
}
.has-text-grape-lighter {
    color: $grape-lighter;
}
.has-text-pink {
    color: $pink;
}
.has-text-pink-light {
    color: $pink-light;
}
.has-text-pink-lighter {
    color: $pink-lighter;
}
.has-text-blue {
    color: #1850DC;
}
.has-text-white {
    color: white;
}
.has-text-black20 {
    color: $black20;
}
.has-text-block40 {
    color: $black40;
}
.has-text-block60 {
    color: $black60;
}

/// MARGIN & PADDING
.is-m-b-4 {
    margin-bottom: 4rem;
}
.is-m-t-2 {
    margin-top: 2rem;
}
.is-m-b-2 {
    margin-bottom: 2rem;   
}
.margin-top1-bottom5 {
    margin-top: 1rem;
    margin-bottom: 5rem;
}

// LINE HEIGHT
.line-height-300per {
    line-height: 300%;
}
.line-height-2-half {
    line-height: 2.5;
}
.line-height-1 {
    line-height: 1;
}
.line-height-2 {
    line-height: 2;
}
.line-height-100per {
    line-height: 100%;
}

//BACKGROUND 
.has-background-paleblue {
    background-color: $pale-blue;
}
.has-background-white {
    background-color: white;
}
.has-background-sky {
    background-color: $sky;
}
.has-background-marine {
    background-color: $marine;
}
.has-background-marine-light {
    background-color: $marine-light;
}
.has-background-marine-lighter {
    background-color: $marine-lighter;
}
.has-background-grape {
    background-color: $grape;
}
.has-background-grape-light {
    background-color: $grape-light;
}
.has-background-grape-lighter {
    background-color: $grape-lighter;
}
.has-background-pink {
    background-color: $pink;
}
.has-background-pink-light {
    background-color: $pink-light;
}
.has-background-pink-lighter {
    background-color: $pink-lighter;
}
.has-background-creme {
    background-color: $creme;
}

//BORDER RADIUS//
.has-radius {
    border-radius: $radius;
}
.has-radius-large {
    border-radius: $radius-large;
}
.has-radius-extra {
    border-radius: $radius-extra;
}

//OTHER HELPERS 
.has-margin-background {
    margin-top: $header-top-padding;
    background: $pale-blue;
    max-width: 100vw;
}
.is-fullheight {
    height: 100%;
}
.is-gap-1 {
    gap: 0.5rem;
}
.is-fit-content {
    width: fit-content;
}
.has-height-fit-content {
    height: fit-content;
}
.is-fullwidth {
    width: 100%;
}
.is-bold {
    font-weight: bold;
}
.hr-burgundy {
    background-color: $grape;
    width: 2.875rem;
    margin: 1rem auto;
}
.nowrap {
    white-space: nowrap ;
}
.wrap {
    white-space: wrap !important;
}
.has-text-wrap {
    text-wrap: wrap;
}
.has-text-nowrap {
    text-wrap: wrap;
}
.width-100per {     
    width: 100%;
}
.width-50per {
    width: 50%;
}
.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}
.hidden {
    display: none;
}

.company {
    &:after {
        content: 'RM & TS Media Ltd, address 11-14 Newry Street, Room SF2 Warrenpoint, Newry, Co. Down. BT34 3JZ Northern Ireland, UK';
    }
}

.company-name {
    &:after {
        content: 'RM & TS Media Ltd'
    }
}

.company-address {
    &:after {
        content: 'The United Kingdom of Great Britain and Northern Ireland, legal address at 11-14 Newry Street, Room SF2, Warrenpoint, Newry, Co. Down. BT34 3JZ, Northern Ireland, UK'
    }
}

.has-cursor-pointer {
    cursor: pointer;
}

.arrow {
    display: block;
    width: 25px;
    height: 12px;
    transform: translateY(0);
    &:before,
    &:after {
        display: block;
        position: absolute;
        top: 50%;
        width: 10px;
        height: 1px;
        background-color: currentColor;
        content: '';
        transition: transform 0.2s ease;
    }
    &:before {
        left: 4px;
        transform: rotate(45deg);
    }
    &:after {
        right: 4px;
        transform: rotate(-45deg);
    }
    &.is-active {
        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}